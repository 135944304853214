import { useCallback, useContext, useMemo } from 'react';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import {
	PopoverTypes,
	QuantityBoxPlusBtnTypes,
} from 'Components/QuantityBox/constants';
import useTodayTomorrowDate from 'Hooks/useTodayTomorrowDate/useTodayTomorrowDate';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import { WarehouseContext } from 'Services/WarehouseService';
import { DeviceServiceContext } from 'Services/DeviceService';
import { LocalizationContext } from 'Services/LocalizationService';
import { DirectQuantityBox, StandardQuantityBox } from 'Components/QuantityBox';
import quantityBoxStyles from 'Components/QuantityBox/styles.module.css';
import { Button, DiscountPercentage } from 'vinisto_ui';
import { BasketContext } from 'Services/BasketService';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { useQuery } from '@tanstack/react-query';
import { DiscountCouponService } from 'vinisto_api_client';
import { BasketService } from 'vinisto_api_client';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { NotificationsContext } from 'Services/NotificationService';

import DisabledShopControlsInfo from '../TemporaryUnavailableInfo';
import IsGiftInfo from '../IsGiftInfo';

import {
	BundleItemVariants,
	QuantityBoxVariants,
	SHOW_EXACT_UNDER_COUNT,
} from './constants';
import { BundleItemProps } from './interfaces';
import bundleItemStyles from './styles.module.css';

import { bundleAdapter } from '@/index';
import {
	VinistoOrderDllModelsApiDiscountCouponDefinitionModelsAmountDiscountCouponDefinition,
	VinistoOrderDllModelsApiDiscountCouponDefinitionModelsBaseDiscountCouponDefinition,
	VinistoOrderDllModelsApiDiscountCouponDefinitionModelsPercentageDiscountCouponDefinition,
} from '@/api-types/order-api';
import { VinistoHelperDllBaseBaseReturn } from '@/api-types/warehouse-api';

const styles: { [key: string]: string } = {
	...quantityBoxStyles,
	...bundleItemStyles,
};

const BundleItem = ({
	bundle,
	variant = BundleItemVariants.STANDARD,
	quantityBox,
	isLoading,
}: BundleItemProps) => {
	//TODO: Create new refactored component for BundlePage
	const t = useContext(LocalizationContext).useFormatMessage();
	const warehouseContext = useContext(WarehouseContext);
	const { isMobile } = useContext(DeviceServiceContext);
	const { handleOnAddToBasket } = useContext(BasketContext);
	const { handleShowErrorNotification } = useContext(NotificationsContext);
	const getLocalizedValue = useLocalizedValue();
	const { isLoggedIn, vinistoUser, anonymousUID } = useContext(
		AuthenticationContext
	);

	const getDateLabel = useTodayTomorrowDate();
	const activeCurrency = useContext(LocalizationContext).activeCurrency;
	const currencyTitle = activeCurrency?.title;

	const bundlePrices = bundle
		? bundleAdapter.fromApi(bundle).bundlePrices
		: null;

	const { isDiscounted, basePrice, discountedPrice } = bundlePrices ?? {};

	const priceWithVat = createCurrencyValue(
		(isDiscounted ? discountedPrice?.valueWithVat : basePrice?.valueWithVat) ??
			0
	);
	const priceWithoutVAT = createCurrencyValue(
		(isDiscounted ? discountedPrice?.value : basePrice?.value) ?? 0
	);

	const basePriceWithVat = createCurrencyValue(basePrice?.valueWithVat ?? 0);
	const basePriceWithoutVat = createCurrencyValue(basePrice?.value ?? 0);

	const availableQuantity = warehouseContext.getQuantity(bundle?.id ?? '') ?? 0;
	const isAvailable = (availableQuantity as number) > 0;
	const isDeleted = bundle?.isDeleted;
	const isTemporaryUnavailable = bundle?.temporaryUnavailable ?? false;
	const isGift = bundle?.isGift ?? false;
	const isSaleOver = bundle?.isSaleOver;
	const url = getLocalizedValue(bundle?.url ?? []);

	const productDetailDiscountDouponsQueryParams = useMemo(
		() => ({ CanBeApplied: true, IsCache: true, IsActive: true }),
		[]
	);

	const { data: discountCoponsData, isLoading: isDiscountCouponsLoading } =
		useQuery(
			['discountCoupons', productDetailDiscountDouponsQueryParams],
			() =>
				DiscountCouponService.getAll(productDetailDiscountDouponsQueryParams),
			{
				// enabled: !!bundle?.id,
				enabled: false,
			}
		);

	const { isPercentageTypeDiscountCoupon, isAmountTypeDiscountCoupon } =
		DiscountCouponService;

	const getDiscountCouponAbsoluteValue = useCallback(
		(
			coupon:
				| VinistoOrderDllModelsApiDiscountCouponDefinitionModelsBaseDiscountCouponDefinition
				| VinistoOrderDllModelsApiDiscountCouponDefinitionModelsAmountDiscountCouponDefinition
				| VinistoOrderDllModelsApiDiscountCouponDefinitionModelsPercentageDiscountCouponDefinition,
			benchmarkPrice: number
		) => {
			if (isPercentageTypeDiscountCoupon(coupon)) {
				return benchmarkPrice * (Number(coupon.percentageDiscount) / 100) ?? 0;
			}
			if (isAmountTypeDiscountCoupon(coupon)) {
				return coupon.amountDiscount?.valueWithVat ?? 0;
			}
			return 0;
		},
		// No need to add dependencies here
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const absoluteDiscountValuesByCoupon = useMemo(() => {
		const result: Record<string, number> = {};

		if (isDiscountCouponsLoading) return result;

		discountCoponsData?.forEach((coupon) => {
			if (!coupon || !coupon.code) return;

			const absoluteValue = getDiscountCouponAbsoluteValue(
				coupon,
				basePrice?.valueWithVat ?? 0
			);

			result[coupon.code] = absoluteValue;
		});

		return result;
	}, [
		basePrice?.valueWithVat,
		discountCoponsData,
		getDiscountCouponAbsoluteValue,
		isDiscountCouponsLoading,
	]);

	const mostValuableDiscountCoupon = useMemo(() => {
		if (isDiscountCouponsLoading) return null;

		return discountCoponsData
			?.filter((coupon) => {
				if (!coupon || !coupon.code) return false;

				const fivePercentOfPrice = (basePrice?.valueWithVat ?? 0) * 0.05;
				const fiftyPercentOfPrice = (basePrice?.valueWithVat ?? 0) * 0.5;

				if (
					absoluteDiscountValuesByCoupon[String(coupon.code)] <=
						fivePercentOfPrice ||
					absoluteDiscountValuesByCoupon[String(coupon.code)] >=
						fiftyPercentOfPrice
				) {
					return false;
				}

				if (coupon.allowedFrom) {
					return (
						Number(coupon.allowedFrom?.valueWithVat) <= Number(priceWithVat)
					);
				}
				return true;
			})

			.sort((a, b) => {
				const aCouponCreator = a.isSupplierDiscount ? 1 : 0;
				const bCouponCreator = b.isSupplierDiscount ? 1 : 0;

				const aDiscountAbsoluteValue =
					absoluteDiscountValuesByCoupon[String(a.code)];

				const bDiscountAbsoluteValue =
					absoluteDiscountValuesByCoupon[String(b.code)];

				return (
					aCouponCreator - bCouponCreator ||
					bDiscountAbsoluteValue - aDiscountAbsoluteValue
				);
			})[0];
	}, [
		absoluteDiscountValuesByCoupon,
		basePrice?.valueWithVat,
		discountCoponsData,
		isDiscountCouponsLoading,
		priceWithVat,
	]);

	const canShopForItem = bundle?.flags.isForLogged ? isLoggedIn : true;

	const isCouponAvailable = canShopForItem && mostValuableDiscountCoupon;

	const priceWhenCouponApplied = mostValuableDiscountCoupon
		? (basePrice?.valueWithVat ?? 0) -
		  getDiscountCouponAbsoluteValue(
				mostValuableDiscountCoupon,
				basePrice?.valueWithVat ?? 0
		  )
		: null;

	const mostValuableDiscountCouponCode =
		mostValuableDiscountCoupon?.code ?? null;

	const handleOnAddToBasketWithDiscountCoupon = useCallback(async () => {
		await BasketService.applyDiscountCoupon({
			discountCouponCode: mostValuableDiscountCouponCode,
			...(isLoggedIn
				? { userLoginHash: vinistoUser.loginHash }
				: { anonymousUserId: anonymousUID.anonymousUserId }),
		})
			.catch((responseError: VinistoHelperDllBaseBaseReturn) => {
				const isCouponAlreadyApplied = responseError?.error?.some(
					(err) =>
						err.specificError === 'DISCOUNT_COUPON_ID_IS_ALREADY_IN_BASKET'
				);

				if (!isCouponAlreadyApplied)
					handleShowErrorNotification('basket.discountCoupon.error');
			})
			.finally(() => {
				handleOnAddToBasket(1, String(bundle?.id), url);
			});
	}, [
		anonymousUID.anonymousUserId,
		bundle?.id,
		handleOnAddToBasket,
		handleShowErrorNotification,
		isLoggedIn,
		mostValuableDiscountCouponCode,
		url,
		vinistoUser.loginHash,
	]);

	const supplier =
		bundle?.supplier?.nameWeb ??
		t({
			id: 'productDetail.seller.name.others',
		});

	if (isSaleOver)
		return (
			<DisabledShopControlsInfo
				isMobile={isMobile}
				translations={{
					cta:
						t({
							id: 'bundle.isSaleOver.productDetail.cta',
						})?.toString() ?? '',
					title:
						t({
							id: 'bundle.isSaleOver.productDetail.title',
						})?.toString() ?? '',
					seller:
						t({
							id: 'productDetail.seller.name',
						})?.toString() ?? '',
					withoutVat:
						t({
							id: 'basket.priceWithoutVAT',
						})?.toString() ?? '',
				}}
				price={`${basePriceWithVat} Kč`}
				priceNoVat={`${basePriceWithoutVat} Kč`}
				supplier={`${supplier}`}
			/>
		);

	if (isTemporaryUnavailable)
		return (
			<DisabledShopControlsInfo
				isMobile={isMobile}
				translations={{
					cta:
						t({
							id: 'bundle.temporaryUnavailable.productDetail.cta',
						})?.toString() ?? '',
					title:
						t({
							id: 'bundle.temporaryUnavailable.productDetail.title',
						})?.toString() ?? '',
					seller:
						t({
							id: 'productDetail.seller.name',
						})?.toString() ?? '',
					withoutVat:
						t({
							id: 'basket.priceWithoutVAT',
						})?.toString() ?? '',
				}}
				price={`${priceWithVat} Kč`}
				priceNoVat={`${priceWithoutVAT} Kč`}
				supplier={`${supplier}`}
			/>
		);

	if (isGift) return <IsGiftInfo />;

	if (variant === BundleItemVariants.COMPACT) {
		return (
			<div className={`${styles.wrapper} ${styles.compact}`}>
				<div className={styles.wrapperInfo}>
					<span className={styles.availableCount}>
						{availableQuantity === undefined || isLoading ? (
							<Skeleton width="120px" />
						) : availableQuantity > SHOW_EXACT_UNDER_COUNT ? (
							t(
								{ id: 'bundleAvailability.inStock.moreThanCount2' },
								{
									valueWrap: (
										<span
											className={cx(styles.availableCountValue, {
												[styles.textGreen]: availableQuantity > 0,
											})}
										>
											{t(
												{
													id: 'bundleAvailability.inStock.moreThanCount2.valueWrap',
												},
												{
													value: (
														<>
															{t(
																{
																	id: 'bundleAvailability.inStock.moreThanCount.info',
																},
																{
																	count: SHOW_EXACT_UNDER_COUNT,
																}
															)}
														</>
													),
												}
											)}
										</span>
									),
								}
							)
						) : (
							<span className={styles.availableCountName}>
								{availableQuantity < 1 && (
									<span className={styles.stockNotAvaible}>
										{t({
											id: 'bundleAvailability.outOfStock',
										})}{' '}
										|{' '}
									</span>
								)}
								{t(
									{ id: 'bundle.warehouse.info.stock' },
									{
										value: (
											<span
												className={cx(styles.availableCountValue, {
													[styles.textGreen]: availableQuantity > 0,
												})}
											>
												{t(
													{ id: 'bundle.warehouse.quantity' },
													{
														count: availableQuantity,
													}
												)}
											</span>
										),
									}
								)}
							</span>
						)}
					</span>
					{isAvailable && warehouseContext.deliveryDate !== undefined && (
						<>
							{' '}
							|{' '}
							<span className={styles.deliveryDate}>
								{t(
									{ id: 'bundle.supplier.deliveryShortDate' },
									{
										date: (
											<span
												className={`${styles.deliveryDateValue} ${styles.textGreen}`}
											>
												{getDateLabel(warehouseContext.deliveryDate)}
											</span>
										),
									}
								)}
							</span>
						</>
					)}
				</div>
				{isCouponAvailable ? (
					<div className={cx(styles.shopWrap, styles.discountCoupon)}>
						<div className="d-flex align-items-center gap-3">
							<div>
								{isLoading ? (
									<Skeleton width="60px" />
								) : (
									<div className={styles.priceWithVat}>
										{t(
											{ id: 'price' },
											{
												value: createCurrencyValue(priceWhenCouponApplied ?? 0),
												currency: currencyTitle,
											}
										)}
									</div>
								)}
							</div>
							<DiscountPercentage
								discountedPriceWithVat={priceWhenCouponApplied ?? 0}
								standardPriceWithVat={basePrice?.valueWithVat ?? 0}
							/>
						</div>
						<div className={styles.discountCouponInfo}>
							{t({ id: 'basket.discountCoupon.addToBasketWithDiscount' })}
							<Button
								className={styles.discountButton}
								onClick={handleOnAddToBasketWithDiscountCoupon}
							>
								{mostValuableDiscountCouponCode}
							</Button>
						</div>
					</div>
				) : (
					<div
						className={cx(
							styles.shopWrap,
							'd-flex align-items-center justify-content-between gap-3'
						)}
					>
						<div className="d-flex align-items-center gap-3">
							<div className={styles.pricesWrap}>
								{isDiscounted && (
									<div
										className={cx(
											styles.priceBeforeDiscount,
											styles.lineThrough
										)}
									>
										{t(
											{ id: 'price' },
											{
												value: basePriceWithVat,
												currency: currencyTitle,
											}
										)}
									</div>
								)}

								{isLoading ? (
									<Skeleton width="60px" />
								) : (
									<div className={styles.priceWithVat}>
										{t(
											{ id: 'price' },
											{
												value: priceWithVat,
												currency: currencyTitle,
											}
										)}
									</div>
								)}
								<div className={styles.priceWithoutVatValue}>
									{isLoading ? (
										<Skeleton width="75px" />
									) : (
										t(
											{ id: 'price.withoutVAT' },
											{
												priceWithCurrency: (
													<span
														className={cx({
															[styles.priceWithoutVatDiscounted]: isDiscounted,
														})}
													>
														{t(
															{ id: 'price' },
															{
																value: priceWithoutVAT,
																currency: currencyTitle,
															}
														)}
													</span>
												),
											}
										)
									)}
								</div>
							</div>
							{isDiscounted && (
								<DiscountPercentage
									discountedPriceWithVat={discountedPrice?.valueWithVat ?? 0}
									standardPriceWithVat={basePrice?.valueWithVat ?? 0}
								/>
							)}
						</div>

						{isAvailable && !isDeleted && (
							<div className={styles.addToBasketWrap}>
								{quantityBox === QuantityBoxVariants.STANDARD ? (
									<StandardQuantityBox
										className={cx(styles.quantityBox, styles.productDetail)}
										bundle={bundle}
										isLoading={isLoading}
										orderLimitation={bundle?.orderLimitation}
									/>
								) : (
									<DirectQuantityBox
										className={cx(styles.quantityBox, styles.productDetail)}
										plusBtnType={QuantityBoxPlusBtnTypes.EXTENDED}
										bundle={bundle}
										isLoading={isLoading}
										orderLimitation={bundle?.orderLimitation}
										countPopover={true}
										popoverType={PopoverTypes.WINDOW}
									/>
								)}
							</div>
						)}
					</div>
				)}
			</div>
		);
	}

	return (
		<div className={cx(styles.wrapper, styles.standard)}>
			{isCouponAvailable ? (
				<>
					<div className={cx(styles.priceBeforeDiscount, 'mb-2')}>
						bez kódu{' '}
						{t(
							{ id: 'price' },
							{
								value: basePriceWithVat,
								currency: currencyTitle,
							}
						)}
					</div>
					<div className={styles.discountCoupon}>
						<div className={styles.priceWithVat}>
							{isLoading ? (
								<Skeleton
									width="70px"
									height="36px"
									className="d-block"
								/>
							) : (
								<div className="d-flex align-items-center gap-3 mt-2 mt-xxl-0">
									<div>
										{t(
											{ id: 'price' },
											{
												value: createCurrencyValue(priceWhenCouponApplied ?? 0),
												currency: currencyTitle,
											}
										)}
									</div>
									<DiscountPercentage
										discountedPriceWithVat={priceWhenCouponApplied ?? 0}
										standardPriceWithVat={basePrice?.valueWithVat ?? 0}
									/>
								</div>
							)}
						</div>
						<div className={styles.discountCouponInfo}>
							{t({ id: 'basket.discountCoupon.addToBasketWithDiscount' })}
							<Button
								className={styles.discountButton}
								onClick={handleOnAddToBasketWithDiscountCoupon}
							>
								{mostValuableDiscountCouponCode}
							</Button>
						</div>
					</div>
				</>
			) : (
				<div>
					<div className={styles.priceWithVat}>
						{isDiscounted && (
							<span
								className={cx(styles.priceBeforeDiscount, styles.lineThrough)}
							>
								{t(
									{ id: 'price' },
									{
										value: basePriceWithVat,
										currency: currencyTitle,
									}
								)}
							</span>
						)}
						{isLoading ? (
							<Skeleton
								width="70px"
								height="36px"
								className="d-block"
							/>
						) : (
							<div className="d-flex align-items-center gap-3 mt-2 mt-xxl-0">
								<div>
									{t(
										{ id: 'price' },
										{
											value: priceWithVat,
											currency: currencyTitle,
										}
									)}
								</div>
								{isDiscounted && (
									<DiscountPercentage
										discountedPriceWithVat={discountedPrice?.valueWithVat ?? 0}
										standardPriceWithVat={basePrice?.valueWithVat ?? 0}
									/>
								)}
							</div>
						)}
					</div>
					<div className={styles.priceWithoutVatValue}>
						{isLoading ? (
							<Skeleton width="75px" />
						) : (
							t(
								{ id: 'price.withoutVAT' },
								{
									priceWithCurrency: (
										<span
											className={cx({
												[styles.priceWithoutVatDiscounted]: isDiscounted,
											})}
										>
											{t(
												{ id: 'price' },
												{
													value: priceWithoutVAT,
													currency: currencyTitle,
												}
											)}
										</span>
									),
								}
							)
						)}
					</div>
				</div>
			)}
			<div className={styles.avaibilityDeliveryWrapper}>
				<span className={styles.availableCount}>
					{availableQuantity === undefined || isLoading ? (
						<Skeleton width="120px" />
					) : availableQuantity > SHOW_EXACT_UNDER_COUNT ? (
						t(
							{ id: 'bundleAvailability.inStock.moreThanCount2' },
							{
								valueWrap: (
									<span
										className={cx(styles.availableCountValue, {
											[styles.textGreen]: availableQuantity > 0,
										})}
									>
										{t(
											{
												id: 'bundleAvailability.inStock.moreThanCount2.valueWrap',
											},
											{
												value: (
													<>
														{t(
															{
																id: 'bundleAvailability.inStock.moreThanCount.info',
															},
															{
																count: SHOW_EXACT_UNDER_COUNT,
															}
														)}
													</>
												),
											}
										)}
									</span>
								),
							}
						)
					) : (
						t(
							{ id: 'bundle.warehouse.info.stock' },
							{
								value: (
									<span
										className={cx(styles.availableCountValue, {
											[styles.textGreen]: availableQuantity > 0,
										})}
									>
										{t(
											{ id: 'bundle.warehouse.quantity' },
											{
												count: availableQuantity,
											}
										)}
									</span>
								),
							}
						)
					)}
					<span
						className={cx(styles.availableCountValue, {
							[styles.textGreen]: availableQuantity > 0,
						})}
					>
						{', '}
						{t(
							{
								id: 'bundle.supplier.avaibility.available',
							},
							{
								value: t({
									id: 'bundle.supplier.avaibility.centralWarehouse',
								}),
							}
						)}
					</span>
				</span>
				{isAvailable ? (
					<div className={styles.avaibilityDelivery}>
						{warehouseContext.deliveryDate !== undefined && (
							<div className={styles.deliveryDate}>
								{t(
									{ id: 'bundle.supplier.deliveryDate' },
									{
										date: (
											<span
												className={`${styles.deliveryDateValue} ${styles.textGreen}`}
											>
												{getDateLabel(warehouseContext.deliveryDate, true)}
											</span>
										),
									}
								)}
							</div>
						)}
					</div>
				) : isLoading ? (
					<Skeleton
						width="100px"
						count={1.6}
						inline
						className="ms-0 me-0"
					/>
				) : (
					<span className="vinisto-wine__stock--not-available fw-bolder">
						{', '}
						{t({
							id: 'bundleAvailability.outOfStock',
						})}
					</span>
				)}
			</div>

			{isAvailable && (
				<>
					{quantityBox === QuantityBoxVariants.STANDARD ? (
						<StandardQuantityBox
							className={cx(
								styles.quantityBox,
								styles.productDetail,
								'other-sellers'
							)}
							bundle={bundle}
							isLoading={isLoading}
							orderLimitation={bundle?.orderLimitation}
						/>
					) : (
						<DirectQuantityBox
							className={cx(
								styles.quantityBox,
								styles.productDetail,
								'other-sellers'
							)}
							plusBtnType={QuantityBoxPlusBtnTypes.EXTENDED}
							bundle={bundle}
							isLoading={isLoading}
							orderLimitation={bundle?.orderLimitation}
						/>
					)}
				</>
			)}
			<div className={styles.seller}>
				{isLoading ? (
					<Skeleton
						width="100px"
						count={1.6}
						inline
						className="ms-0 me-0"
					/>
				) : (
					<>
						<span className={styles.sellerTitle}>{`${t({
							id: 'productDetail.seller.name',
						})}: `}</span>
						<span className={styles.sellerName}>{supplier}</span>
					</>
				)}
				{', '}
				{t(
					{
						id: 'bundle.warehouse.from',
					},
					{
						value: (
							<span className={styles.bolder}>
								{t({
									id: 'bundle.warehouse.from.vinisto',
								})}
							</span>
						),
					}
				)}
			</div>
		</div>
	);
};

export default BundleItem;
