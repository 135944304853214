import api from '@/api';
import {
	DiscountCouponsListParams,
	VinistoHelperDllEnumsDiscountCouponDiscountCouponType,
	VinistoOrderDllModelsApiDiscountCouponDefinitionModelsAmountDiscountCouponDefinition,
	VinistoOrderDllModelsApiDiscountCouponDefinitionModelsBaseDiscountCouponDefinition,
	VinistoOrderDllModelsApiDiscountCouponDefinitionModelsPercentageDiscountCouponDefinition,
	VinistoOrderDllModelsApiReturnDataDiscountCouponsReturn,
} from '@/api-types/order-api';

const isAmountTypeDiscountCoupon = (
  coupon:
    | VinistoOrderDllModelsApiDiscountCouponDefinitionModelsBaseDiscountCouponDefinition
    | VinistoOrderDllModelsApiDiscountCouponDefinitionModelsAmountDiscountCouponDefinition
    | VinistoOrderDllModelsApiDiscountCouponDefinitionModelsPercentageDiscountCouponDefinition
    | undefined
    | null
): coupon is VinistoOrderDllModelsApiDiscountCouponDefinitionModelsAmountDiscountCouponDefinition =>
  coupon?.discountCouponType ===
  VinistoHelperDllEnumsDiscountCouponDiscountCouponType.AMOUNT;

const isPercentageTypeDiscountCoupon = (
  coupon:
    | VinistoOrderDllModelsApiDiscountCouponDefinitionModelsBaseDiscountCouponDefinition
    | VinistoOrderDllModelsApiDiscountCouponDefinitionModelsAmountDiscountCouponDefinition
    | VinistoOrderDllModelsApiDiscountCouponDefinitionModelsPercentageDiscountCouponDefinition
    | undefined
    | null
): coupon is VinistoOrderDllModelsApiDiscountCouponDefinitionModelsPercentageDiscountCouponDefinition =>
  coupon?.discountCouponType ===
  VinistoHelperDllEnumsDiscountCouponDiscountCouponType.PERCENTAGE;

const getAll = async (params?: DiscountCouponsListParams) => {
	const res =
		await api.get<VinistoOrderDllModelsApiReturnDataDiscountCouponsReturn>(
			'order-api/discount-coupons',
			params
		);

	if (res.discountCoupons === null || res.discountCoupons === undefined) {
		throw new Error('No discount coupons data in response');
	}

	return res.discountCoupons;
};

/*
 Note: part of the coupon functionality (adding, removing, etc.) part of the basket service
(This is due to the fact it was structured this way in the api layer)
*/
const DiscountCouponService = {
  isAmountTypeDiscountCoupon,
  isPercentageTypeDiscountCoupon,
	getAll,
};

export default DiscountCouponService;
