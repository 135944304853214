import { AbstractAdapter } from '../abstract-adapter';
import { DEFAULT_CURRENCY } from '../../shared';

import Price, { DiscountedPrice, VolumeDiscount } from './index';

import {
	VinistoCommonDllModelsApiPricesPriceDiscountSet,
	VinistoCommonDllModelsApiPricesPriceDiscountSupplier,
	VinistoCommonDllModelsApiPricesPriceDiscountVinisto,
	VinistoHelperDllEnumsCurrency,
	VinistoHelperDllEnumsVatRate,
	VinistoCommonDllModelsApiPricesPrice,
	VinistoCommonDllModelsApiPricesPriceDiscountVolume,
} from '@/api-types/product-api';
import convertDateToDayjs from '@/utils/convert-date-to-dayjs';
import convertPrice from '@/utils/convert-price';
import { NUMBER_OF_DISPLAYED_VOLUME_DISCOUNTS } from './constants';

class PriceAdapter extends AbstractAdapter<
	Price,
	VinistoCommonDllModelsApiPricesPrice
> {
	fromApi(apiData: VinistoCommonDllModelsApiPricesPrice): Price {
		return {
			currency:
				(apiData?.currency as VinistoHelperDllEnumsCurrency) ??
				DEFAULT_CURRENCY,
			value: convertPrice(apiData?.value ?? 0),
			valueWithVat: convertPrice(apiData?.valueWithVat) ?? 0,
			vat: apiData?.vat ?? VinistoHelperDllEnumsVatRate.BaseVat,
			vatValue: apiData && 'vatValue' in apiData ? apiData?.vatValue ?? 21 : 21,
			priceType: apiData?.level,
		};
	}

	fromApiWithDiscount(
		apiData:
			| VinistoCommonDllModelsApiPricesPriceDiscountSet
			| VinistoCommonDllModelsApiPricesPriceDiscountSupplier
			| VinistoCommonDllModelsApiPricesPriceDiscountVinisto
			| undefined
	): DiscountedPrice {
		return {
			currency:
				(apiData?.currency as VinistoHelperDllEnumsCurrency) ??
				DEFAULT_CURRENCY,
			value: convertPrice(apiData?.value ?? 0),
			valueWithVat: convertPrice(apiData?.valueWithVat) ?? 0,
			vat: apiData?.vat ?? VinistoHelperDllEnumsVatRate.BaseVat,
			// @todo: once BE starts sending vatValue, replace with actual data
			vatValue: 0,
			discountType: apiData?.type,
			validFrom: convertDateToDayjs(apiData?.validFrom),
			validTo: convertDateToDayjs(apiData?.validTo),
		};
	}

	fromApiWithVolumeDiscount(
		apiData: VinistoCommonDllModelsApiPricesPriceDiscountVolume & {
			value: number;
			valueWithVat: number;
			vatValue: number;
		}
	): VolumeDiscount {
		const {
			value: baseOrDiscountedValue,
			valueWithVat: baseOrDiscountedValueWithVat,
			vatValue,
		} = apiData;
		// Just to remind these properties comes from the base price...

		return {
			currency:
				(apiData?.currency as VinistoHelperDllEnumsCurrency) ??
				DEFAULT_CURRENCY,

			vat: apiData?.vat ?? VinistoHelperDllEnumsVatRate.BaseVat,
			// @todo: once BE starts sending vatValue, replace with actual data
			vatValue: 0,
			discountType: apiData?.type,
			validFrom: convertDateToDayjs(apiData?.validFrom),
			validTo: convertDateToDayjs(apiData?.validTo),
			priceId: apiData.priceId ?? '',
			isSupplierDiscount: apiData.isSupplierDiscount ?? false,
			values: Object.entries(apiData?.values ?? {})
				.map(([key, value]) => {
					const quantity = Number(key);
					const valueWithVat = value * (1 + (vatValue / 100));
					return {
						quantity,
						value: convertPrice(value),
						totalValue: value * quantity,
						valueWithVat: convertPrice(valueWithVat),
						totalValueWithVat: convertPrice(valueWithVat * quantity),
						totalSavings: Math.max(
							convertPrice(baseOrDiscountedValue * quantity - value * quantity),
							0
						),
						totalSavingsWithVat: Math.max(
							convertPrice(
								baseOrDiscountedValueWithVat * quantity -
									valueWithVat * quantity
							),
							0
						),
					};
				})
				.sort((a, b) => a.quantity - b.quantity)
				.slice(0, NUMBER_OF_DISPLAYED_VOLUME_DISCOUNTS),
		};
	}
}

export default PriceAdapter;
