import { BasketContext } from 'Services/BasketService';
import { DeviceServiceContext } from 'Services/DeviceService';
import { LocalizationContext } from 'Services/LocalizationService';
import { useContext } from 'react';
import { Button, buttonSizes, buttonVariants } from 'vinisto_ui';
import { VolumeDiscount } from 'vinisto_ui';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import { AuthenticationContext } from 'Services/AuthenticationService/context';
import { ModalContext } from 'Components/Modal/context';
import { LOGIN_MODAL } from 'Components/Modal/constants';

import { VolumeDiscount as VolumeDiscountType } from '@/domain/price';

interface VolumeDiscountSectionProps {
	bundleId: string;
	isForLogged: boolean;
	volumeDiscount: VolumeDiscountType;
	warehouseCount: number;
	basePrice: number;
	discountPrice: number;
}

const VolumeDiscountSection = ({
	bundleId,
	isForLogged = false,
	volumeDiscount,
	warehouseCount,
	basePrice,
	discountPrice,
}: VolumeDiscountSectionProps) => {
	const t = useContext(LocalizationContext).useFormatMessage();
	const { isDesktop } = useContext(DeviceServiceContext);
	const { basketState, handleOnChangeItemQuantity } = useContext(BasketContext);
	const authenticationContext = useContext(AuthenticationContext);
	const modalContext = useContext(ModalContext);

	const itemInBasket = basketState?.items?.find(
		// @ts-expect-error basketState needs proper typing
		(item) => item?.bundleId === bundleId
	);

	const quantityInBasket = itemInBasket?.quantity ?? 0;

	const values = volumeDiscount.values.filter(
		(item) =>
			item.quantity < warehouseCount &&
			item.valueWithVat < basePrice &&
			(discountPrice === 0 || item.valueWithVat < discountPrice)
	);

	if (values.length === 0) {
		return null;
	}

	return (
		<VolumeDiscount.Container
			title={t({
				id: isDesktop
					? 'volumeDiscount.title.desktop'
					: 'volumeDiscount.title.mobile',
			})}
			className={'my-4'}
		>
			<VolumeDiscount.List>
				{values.map((item, index) => {
					if (item.quantity > warehouseCount) {
						return null;
					}
					return (
						<VolumeDiscount.ListItem
							key={index}
							text={t(
								{ id: 'volumeDiscount.content' },
								{
									amount: (
										<VolumeDiscount.Emphasized>
											{t({ id: 'amount.pcs' }, { count: item.quantity })}
										</VolumeDiscount.Emphasized>
									),
									cumulativePrice: (
										<VolumeDiscount.DoubleEmphasized>
											{createCurrencyValue(item.totalValueWithVat)}{' '}
											{/*volumeDiscount.currency*/}Kč
										</VolumeDiscount.DoubleEmphasized>
									),
									savings: (
										<VolumeDiscount.Emphasized>
											{createCurrencyValue(item.totalSavingsWithVat)}{' '}
											{/*volumeDiscount.currency*/}Kč
										</VolumeDiscount.Emphasized>
									),
								}
							)}
							cta={
								!authenticationContext.isLoggedIn && isForLogged ? (
									<Button
										variant={buttonVariants.CTA}
										size={buttonSizes.S}
										onClick={() =>
											modalContext.handleOpenModal(LOGIN_MODAL, {})
										}
									>
										{t({ id: 'carousel.button.loginAndShop' })}
									</Button>
								) : (
									<Button
										variant={buttonVariants.CTA}
										size={buttonSizes.S}
										onClick={() =>
											handleOnChangeItemQuantity(
												quantityInBasket + item.quantity,
												bundleId
											)
										}
									>
										{t({ id: 'bundle.button.buy' })}
									</Button>
								)
							}
						/>
					);
				})}
			</VolumeDiscount.List>
		</VolumeDiscount.Container>
	);
};

export default VolumeDiscountSection;
