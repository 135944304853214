import { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { get } from 'lodash-es';
import getFlagSpecification from 'Helpers/getFlagSpecification';
import getBundleImage, { IMAGE_SIZE_THUMB_64x80 } from 'Helpers/getBundleImage';
import { LocalizationContext } from 'Services/LocalizationService';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import { getDiscountPriceValues } from 'vinisto_shared/src/price/get-discount-prices';

import { IOrderOverviewItemProps } from './interface';
import styles from './styles.module.css';

const OrderOverviewItem = (props: IOrderOverviewItemProps) => {
	const localizationContext = useContext(LocalizationContext);
	const t = localizationContext.useFormatMessage();

	const bundleData = get(props, 'cartItem.bundle', {});
	const isLoading = get(props, 'isLoading', false);

	const supplierName = get(bundleData, 'supplier.nameWeb', '');

	const getLocalizedValue = useLocalizedValue();

	const { title: currencyTitle } = localizationContext.activeCurrency;

	const bundleQuantity = props.cartItem?.quantity ?? 1;
	// @ts-expect-error define proper interface for cartItem (it's rubbish now)
	const basePrice = props.cartItem?.itemPrice;
	// @ts-expect-error define proper interface for cartItem (it's rubbish now)
	const discountedPrice = props.cartItem?.itemDiscountPrice;

	const { discountedPriceWithoutVat, discountedPriceWithVat, isDiscounted } =
		getDiscountPriceValues({
			quantityInBasket: bundleQuantity,
			basePrice,
			discountedPrice,
		});

	const priceWithVat = isDiscounted
		? discountedPriceWithVat
		: basePrice?.valueWithVat;

	const priceWithoutVat = isDiscounted
		? discountedPriceWithoutVat
		: basePrice?.value;

	const totalPriceWithVAT = createCurrencyValue(
		priceWithVat ?? 0,
		bundleQuantity
	);
	const totalPriceWithoutVAT = createCurrencyValue(
		priceWithoutVat ?? 0,
		bundleQuantity
	);

	const flagSpecification = getFlagSpecification(
		get(bundleData, 'specificationDetails', [])
	);

	return (
		<div className={styles.item}>
			<div className={styles.imgWrap}>
				{isLoading ? (
					<Skeleton
						containerClassName="w-100"
						height="80px"
					/>
				) : (
					<img
						src={getBundleImage(
							get(bundleData, 'images', []),
							IMAGE_SIZE_THUMB_64x80
						)}
						alt={`${t({ id: 'alt.bundleImage' })}`}
					/>
				)}
			</div>
			<div className={styles.dataWrap}>
				<div className={styles.data}>
					<div className={styles.wineName}>
						{isLoading ? (
							<Skeleton width="120px" />
						) : (
							getLocalizedValue(get(bundleData, 'name', [])) ?? '-'
						)}
					</div>
				</div>
				<div className={styles.data}>
					{isLoading ? (
						<Skeleton width="90px" />
					) : (
						<div className={styles.wineryNameWrap}>
							{get(flagSpecification, 'component') &&
								get(flagSpecification, 'component')}
							<span className={styles.wineryName}>
								{get(flagSpecification, 'shortVariety') &&
									get(flagSpecification, 'shortVariety')}
							</span>
						</div>
					)}
				</div>
				<div className={styles.data}>
					<div className={styles.wineCountWrap}>
						{isLoading ? (
							<Skeleton width="30px" />
						) : (
							<div>
								<div className={styles.wineCount}>
									{`${t(
										{ id: 'basket.pcs' },
										{
											count: bundleQuantity,
										}
									)}`}
								</div>
								<div className={styles.wineSeller}>
									{t(
										{ id: 'bundle.supplierSmall.name' },
										{
											name: (
												<span className="vinisto-color-success">
													{supplierName.length > 0
														? supplierName
														: t({
																id: 'productDetail.seller.name.others',
														  })}
												</span>
											),
										}
									)}
								</div>
							</div>
						)}
					</div>
					<div className="text-end">
						<div>
							{isLoading ? (
								<Skeleton width="100px" />
							) : (
								<>
									<span className={styles.winePrice}>
										{`${totalPriceWithVAT} ${currencyTitle}`}
									</span>
								</>
							)}
						</div>
						<div className={styles.winePriceWithoutVat}>
							{isLoading ? (
								<Skeleton
									width="110px"
									height="15px"
								/>
							) : (
								<>
									{`${t({
										id: 'basket.priceWithoutVAT',
									})} `}
									<span className="fw-normal">
										{`${totalPriceWithoutVAT} ${currencyTitle}`}
									</span>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderOverviewItem;
