import api from '@/api';
import {
	VinistoBasketDllModelsApiBasketBasketSetDiscountCouponParameters,
	VinistoBasketDllModelsApiReturnDataBasketReturn,
} from '@/api-types/basket-api';

const applyDiscountCoupon = async (
	params: VinistoBasketDllModelsApiBasketBasketSetDiscountCouponParameters
) => {
	// @ts-expect-error: There are slightly different VinistoHelperDllBaseError types accross the apis
	const res = await api.put<VinistoBasketDllModelsApiReturnDataBasketReturn>(
		'basket-api/basket/SetDiscountCoupon',
		undefined,
		params
	);
	return res;
};

const BasketService = {
  applyDiscountCoupon,
};

export { BasketService };