import { useContext, useEffect, useState } from 'react';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { LangValuePair } from 'Hooks/useLocalizedValue/interfaces';
import { BasketContext } from 'Services/BasketService';
import { WarehouseContext } from 'Services/WarehouseService';
import { VinistoBasketDllModelsApiBasketBasketBundle } from 'vinisto_api_client/src/api-types/basket-api';
import { VinistoProductDllModelsApiBundleBundle } from 'vinisto_api_client/src/api-types/product-api';

export const useStandardQuantityBox = (
	bundle: VinistoProductDllModelsApiBundleBundle | null | undefined,
	shouldRedirectToCrossSell = true
) => {
	const getLocalizedValue = useLocalizedValue();
	const { handleOnAddToBasket, basketState } = useContext(BasketContext);
	const { getQuantity } = useContext(WarehouseContext);
	const availableCount = getQuantity(bundle?.id ?? '');
	const [count, setCount] = useState(availableCount ? 1 : 0);

	const quantityInBasket =
		(basketState?.items as VinistoBasketDllModelsApiBasketBasketBundle[])?.find(
			(item) => item?.bundleId === bundle?.id
		)?.quantity ?? 0;

	const onCountChange = (value: string) => {
		if (
			value?.length > 0 &&
			value?.split('')?.some((number: string) => Number.isNaN(parseInt(number)))
		) {
			return;
		} else if (value === '') {
			setCount(-1);
		} else if (
			!Number.isNaN(parseInt(value)) &&
			typeof availableCount === 'number' &&
			parseInt(value) > availableCount
		) {
			setCount(availableCount);
		} else if (
			!Number.isNaN(parseInt(value)) &&
			typeof availableCount === 'number' &&
			parseInt(value) < 1
		) {
			setCount(1);
		} else {
			setCount(parseInt(value));
		}
	};

	const onBlur = () => {
		if (
			typeof availableCount === 'number' &&
			availableCount > 0 &&
			count === -1
		) {
			setCount(1);
		}
	};

	const onIncrement = () => {
		if (typeof availableCount === 'number' && count < availableCount) {
			setCount((count) => count + 1);
		}
	};

	const onDecrement = () => {
		if (typeof availableCount === 'number' && count > 1) {
			setCount((count) => count - 1);
		}
	};

	const onAddToBasket = () =>
		handleOnAddToBasket(
			count,
			bundle?.id as string,
			getLocalizedValue(bundle?.url as LangValuePair[]),
			availableCount,
			shouldRedirectToCrossSell
		);

	useEffect(() => {
		if (
			typeof availableCount === 'number' &&
			availableCount > 0 &&
			count === 0
		) {
			setCount(1);
		}
	}, [availableCount]);

	return {
		count,
		onIncrement,
		onDecrement,
		onCountChange,
		onBlur,
		onAddToBasket,
		availableCount,
		quantityInBasket,
	};
};
