import { dayjsInstance as dayjs } from 'vinisto_shared';
import { AbstractAdapter } from '../abstract-adapter';
import { languages } from '../../shared';
import {
	bundleAdapter,
	bundleEvaluationAdapter,
	bundleItemAdapter,
	bundleSpecificationDetailAdapter,
	imageAdapter,
	orderLimitationAdapter,
	priceAdapter,
	productAdapter,
	supplierAdapter,
	tagAdapter,
} from '../../index';

import { Bundle } from '.';

import {
	VinistoCommonDllModelsApiPricesPriceDiscountSet,
	VinistoCommonDllModelsApiPricesPriceDiscountSupplier,
	VinistoCommonDllModelsApiPricesPriceDiscountVinisto,
	VinistoCommonDllModelsApiPricesPriceDiscountVolume,
	VinistoHelperDllEnumsCurrency,
	VinistoHelperDllEnumsPriceDiscountType,
	VinistoProductDllModelsApiBundleBundle,
} from '@/api-types/product-api';

import convertDateToDayjs from '@/utils/convert-date-to-dayjs';
import { DiscountedPrice, VolumeDiscount } from '../price';

const isPriceActive = (
	price:
		| VinistoCommonDllModelsApiPricesPriceDiscountSet
		| VinistoCommonDllModelsApiPricesPriceDiscountSupplier
		| VinistoCommonDllModelsApiPricesPriceDiscountVinisto
) => {
	if (price.validFrom === null && price.validTo === null) return true;

	const validFrom = convertDateToDayjs(price.validFrom);
	const validTo = convertDateToDayjs(price.validTo);
	const isActive = dayjs().isBetween(validFrom, validTo, undefined, '[]');

	return isActive;
};

const isVolumeDiscount = (
	price:
		| VinistoCommonDllModelsApiPricesPriceDiscountSet
		| VinistoCommonDllModelsApiPricesPriceDiscountSupplier
		| VinistoCommonDllModelsApiPricesPriceDiscountVinisto
		| VinistoCommonDllModelsApiPricesPriceDiscountVolume
): price is VinistoCommonDllModelsApiPricesPriceDiscountVolume => {
	return price.type === VinistoHelperDllEnumsPriceDiscountType.VolumeDiscount;
};

const mapApiPricesToBundlePrices = (
	apiData: VinistoProductDllModelsApiBundleBundle,
	activeCurrency: VinistoHelperDllEnumsCurrency = VinistoHelperDllEnumsCurrency.CZK
) => {
	const { isSet } = apiData;
	const prices = apiData.prices.filter(
		(price) => price.currency === activeCurrency
	);
	const priceDiscounts =
		apiData.priceDiscounts?.filter(
			(price) => price.currency === activeCurrency
		) ?? [];

	const activePriceDiscounts = priceDiscounts.filter(isPriceActive);

	const highestAvailableB2cLevelPrice = prices
		.filter((price) => price.level?.startsWith('B2cLevel'))
		.sort((a, b) => {
			const aLevel = Number(a.level?.replace('B2cLevel', ''));
			const bLevel = Number(b.level?.replace('B2cLevel', ''));
			return bLevel - aLevel;
		})[0];

	const basePrice = priceAdapter.fromApi(highestAvailableB2cLevelPrice);

	const b2bPrices = apiData.prices
		.filter((price) => price.level?.startsWith('B2bLevel'))
		.map((price) => priceAdapter.fromApi(price));

	const vinistoOrSupplierDiscount = activePriceDiscounts.find(
		(
			price
		): price is
			| VinistoCommonDllModelsApiPricesPriceDiscountSupplier
			| VinistoCommonDllModelsApiPricesPriceDiscountVinisto =>
			price.type === VinistoHelperDllEnumsPriceDiscountType.SupplierDiscount ||
			price.type === VinistoHelperDllEnumsPriceDiscountType.VinistoDiscount
	);

	const setDiscount = activePriceDiscounts.find(
		(price): price is VinistoCommonDllModelsApiPricesPriceDiscountSet =>
			price.type === VinistoHelperDllEnumsPriceDiscountType.SetDiscount
	);

	const volumeDiscount = activePriceDiscounts.find(
		(price): price is VinistoCommonDllModelsApiPricesPriceDiscountVolume =>
			price.type === VinistoHelperDllEnumsPriceDiscountType.VolumeDiscount
	);

	if (
		!highestAvailableB2cLevelPrice &&
		!vinistoOrSupplierDiscount &&
		!setDiscount
	) {
		// eslint-disable-next-line no-console
		console.warn('Missing at least one price or discount');
	}

	const discountedPrice = (() => {
		if (isSet && setDiscount) {
			return {
				...priceAdapter.fromApiWithDiscount(setDiscount),
				// @todo: once BE starts sending vatValue, remove line below as the priceadapter will handle this value with actual data
				vatValue: basePrice.vatValue,
			};
		}
		if (vinistoOrSupplierDiscount) {
			return {
				...priceAdapter.fromApiWithDiscount(vinistoOrSupplierDiscount),
				// @todo: once BE starts sending vatValue, remove line below as the priceadapter will handle this value with actual data
				vatValue: basePrice.vatValue,
			};
		}
		return null;
	})();

	const volumeDiscountWithCalculatedPricesAndSavings = volumeDiscount
		? priceAdapter.fromApiWithVolumeDiscount({
				...volumeDiscount,
				value:
					Math.min(basePrice.value, discountedPrice?.value ?? Infinity) ?? 0,
				valueWithVat:
					Math.min(
						basePrice.valueWithVat,
						discountedPrice?.valueWithVat ?? Infinity
					) ?? 0,
				vatValue: basePrice.vatValue,
		  })
		: null;

	const allDiscountedPrices: (DiscountedPrice | VolumeDiscount)[] =
		priceDiscounts.map((price) => {
			if (isVolumeDiscount(price)) {
				return priceAdapter.fromApiWithVolumeDiscount({
					...price,
					value: basePrice.value,
					valueWithVat: basePrice.valueWithVat,
					vatValue: basePrice.vatValue,
				});
			}
			return {
				...priceAdapter.fromApiWithDiscount(price),
				// @todo: once BE starts sending vatValue, remove line below as the priceadapter will handle this value with actual data
				vatValue: basePrice.vatValue,
			};
		});

	const discountDifferenceAsAmount =
		Number(discountedPrice?.value) - Number(basePrice?.value);
	const discountDifferenceWithVatAsAmount =
		Number(discountedPrice?.valueWithVat) - Number(basePrice?.valueWithVat);
	const discountDifferenceAsPercentage =
		(discountDifferenceWithVatAsAmount / Number(basePrice?.valueWithVat)) * 100;
	const isDiscounted =
		!Number.isNaN(discountDifferenceWithVatAsAmount) &&
		discountDifferenceWithVatAsAmount < 0;

	// Controversial - should prevent displaying lower prices than actual selling prices,
	// but it is not an ideal solution. Maybe set a flag and handle it in the UI?
	if (!isDiscounted && discountedPrice) {
		basePrice.value = discountedPrice.value;
		basePrice.valueWithVat = discountedPrice.valueWithVat;
	}

	return {
		basePrice,
		discountedPrice,
		volumeDiscount: volumeDiscountWithCalculatedPricesAndSavings,
		allDiscountedPrices,
		b2bPrices,
		discountDifferenceAsAmount: Number.isNaN(discountDifferenceAsAmount)
			? 0
			: discountDifferenceAsAmount,
		discountDifferenceWithVatAsAmount: Number.isNaN(
			discountDifferenceWithVatAsAmount
		)
			? 0
			: discountDifferenceWithVatAsAmount,
		discountDifferenceAsPercentage: Number.isNaN(discountDifferenceAsPercentage)
			? 0
			: discountDifferenceAsPercentage,
		isDiscounted,
		...(setDiscount ? { setItemsPrices: setDiscount.values } : {}),
	};
};

class BundleAdapter extends AbstractAdapter<
	Bundle,
	VinistoProductDllModelsApiBundleBundle
> {
	fromApi(apiData: VinistoProductDllModelsApiBundleBundle): Bundle {
		const id = apiData.id;

		if (!id) throw new Error('No id in bundle');

		const orderLimitation =
			apiData.orderLimitation && apiData.orderLimitation.limit !== undefined
				? orderLimitationAdapter.fromApi(apiData.orderLimitation ?? {})
				: undefined;

		return {
			id,
			name: this.convertMultiLangValue(apiData.name),
			description: this.convertMultiLangValue(apiData.description),
			shortDescription: this.convertMultiLangValue(apiData.shortDescription),
			keywords: this.convertMultiLangValue(apiData.keywords),
			text: this.convertMultiLangValue(apiData.text),
			url: this.convertMultiLangValue(apiData.url),
			language: apiData.language ?? languages[0],
			prices: apiData.prices.map((price) => priceAdapter.fromApi(price)),
			discountedPrices:
				apiData.priceDiscounts?.map((price) =>
					priceAdapter.fromApiWithDiscount(price)
				) ?? [],
			bundlePrices: mapApiPricesToBundlePrices(apiData),
			items: apiData.items?.map((item) => bundleItemAdapter.fromApi(item)),
			categoryIds: apiData.categories, // TODO: @hynek refaktorovat na categories
			products: apiData.productsDetail?.map((product) =>
				productAdapter.fromApi(product)
			),
			setBundles: apiData.setBundles?.map((bundle) =>
				bundleAdapter.fromApi(bundle)
			),
			alternativeBundles: apiData.alternativeBundleObjects?.map((bundle) =>
				bundleAdapter.fromApi(bundle)
			),
			tags: apiData.tagsDetail?.map((tag) => tagAdapter.fromApi(tag)),
			images: apiData.images
				?.map((image) => imageAdapter.fromApi(image))
				.filter((images) => Object.keys(images).length),
			supplier: apiData.supplier
				? supplierAdapter.fromApi(apiData.supplier)
				: null,
			specificationDetails: apiData.specificationDetails?.map((spec) =>
				bundleSpecificationDetailAdapter.fromApi(spec)
			),
			bundleEvaluation: bundleEvaluationAdapter.fromApi(
				apiData.bundleEvaluation ?? {}
			),
			orderLimitation,

			scoring: apiData.scoring ?? 0,
			scoringWarehouse: apiData.scoringWarehouse ?? 0,
			scoringDiscount: apiData.scoringDiscount ?? 0,
			scoringAdmin: apiData.scoringAdmin ?? 0,
			piecesPerPackage: apiData.piecesPerPackage ?? 0,
			packagesOnPallet: apiData.packagesOnPallet ?? 0,
			flags: {
				isSet: apiData.isSet ?? false,
				isB2B: apiData.isB2b ?? false,
				isClearanceSale: apiData.isClearanceSale ?? false,
				isDeliveryFree: apiData.isDeliveryFree ?? false,
				isDeleted: apiData.isDeleted ?? false,
				isEnabled: apiData.isEnabled ?? false,
				isForLogged: apiData.isForLogged ?? false,
				isGift: apiData.isGift ?? false,
				isTemporaryUnavailable: apiData.temporaryUnavailable ?? false,
				canSendToWms: apiData.canSendToWms ?? false,
				isSaleOver: apiData.isSaleOver ?? false,
			},
		};
	}
}

export default BundleAdapter;
