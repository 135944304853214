import { useContext } from 'react';
import { VinistoProductDllModelsApiBundleBundle } from 'vinisto_api_client/src/api-types/product-api';
import { LocalizationContext } from 'Services/LocalizationService';
import { useDirectQuantityBox } from 'Components/QuantityBox/Variants/DirectQuantityBox/hooks';
import createCurrencyValue from 'Helpers/createCurrencyValue';
import useLocalizedCurrency from 'Hooks/useLocalizedCurrency';
import { BasketContext } from 'Services/BasketService';
import { getDiscountPriceValues } from 'vinisto_shared/src/price/get-discount-prices';

import { VinistoBasketDllModelsApiBasketBasketBundle } from '@/api-types/basket-api';

export const useProductBasketCount = (
	bundle: VinistoProductDllModelsApiBundleBundle | null
) => {
	const localizationContext = useContext(LocalizationContext);
	const basketContext = useContext(BasketContext);
	const t = localizationContext.useFormatMessage();

	const cartItem = (basketContext.basketState?.items ?? []).find(
		(item: VinistoBasketDllModelsApiBasketBasketBundle) => {
			return item.bundleId === bundle?.id;
		}
	);

	const { quantityInBasket } = useDirectQuantityBox(bundle);

	const isDiscounted = cartItem?.itemDiscountPrice !== null;

	const basePrice = cartItem?.itemPrice;

	const discountedPrice = cartItem?.itemDiscountPrice;

	const { discountedPriceWithVat } = getDiscountPriceValues({
		quantityInBasket,
		basePrice,
		discountedPrice,
	});

	const priceWithVat = isDiscounted
		? discountedPriceWithVat
		: basePrice?.valueWithVat;

	const basketBundlesPriceWithCurrency = createCurrencyValue(
		priceWithVat ?? 0,
		quantityInBasket
	);
	const getLocalizedCurrency = useLocalizedCurrency();
	const localizedCurrency = getLocalizedCurrency(
		basketContext.basketState?.currency
	)?.title;

	const basketBundlesPriceWithCurrencyLocalized = `${basketBundlesPriceWithCurrency} ${localizedCurrency}`;
	const basketQuantityPopover = bundle
		? quantityInBasket &&
		  t(
				{ id: 'popover.addedToBasket' },
				{
					quantity: <strong> {quantityInBasket} </strong>,
					priceWithCurrency: (
						<strong>{basketBundlesPriceWithCurrencyLocalized}</strong>
					),
				}
		  )
		: null;

	return {
		basketQuantityPopover,
	};
};
