import Carousel from 'Components/Carousel';
import { HOME_PAGE_PRODUCTS_CAROUSEL } from 'Components/Carousel/constants';
import { CARD_TYPE, ICarouselProps } from 'Components/Carousel/interfaces';
import ContainerFullWidth from 'Components/View/ContainerFullWidth';
import { VinistoProductDllModelsApiBundleBundle } from 'vinisto_api_client/src/api-types/product-api';

type TCarouselSectionProps = {
	data: VinistoProductDllModelsApiBundleBundle[] | undefined;
	title: string;
	isLoading?: boolean;
} & Partial<ICarouselProps>;

const CarouselSection = ({
	data,
	title,
	isLoading = false,
	...carouselProps
}: TCarouselSectionProps) => {
	if (!data) return <></>;
	return (
		<>
			{data?.length > 0 && (
				<ContainerFullWidth>
					<div
						className="position-relative"
						data-scroll-target="temporary_unavailable_cta"
					>
						<p className="h2 vinisto-card__heading">{title}</p>
						<Carousel
							{...carouselProps}
							isLoading={isLoading}
							data={data}
							carouselType={HOME_PAGE_PRODUCTS_CAROUSEL}
							cardType={CARD_TYPE.CAROUSEL_CLASSIC}
							showAddToBasketBtn={true}
						/>
					</div>
				</ContainerFullWidth>
			)}
		</>
	);
};

export default CarouselSection;
