import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import {
	forEach,
	get,
	isInteger,
	isNumber,
	join,
	round,
	size,
	upperFirst,
} from 'lodash-es';
import createFormattedDecimalNumber from 'Helpers/createFormattedDecimalNumber';
import useLocalizedValue from 'Hooks/useLocalizedValue';
import { BundlesWithFiltersContext } from 'Pages/Category/Components/CategoryBundlesWithFilters/context';
import { LocalizationContext } from 'Services/LocalizationService';
import { DeviceServiceContext } from 'Services/DeviceService';
import { ShareProductLink } from 'vinisto_ui';
import styles from 'Pages/Category/Components/CategoryHeader/styles.module.css';
import { useLocation } from 'react-router-dom';

import { TagContext } from '../../context';

const TagHeader = () => {
	const localizationContext = useContext(LocalizationContext);
	const { tagData } = useContext(TagContext);
	const { activeSpecificationFilters, activeTagFilters, specificationsQuery } =
		useContext(BundlesWithFiltersContext);
	const location = useLocation();

	const t = localizationContext.useFormatMessage();
	const getLocalizedValue = useLocalizedValue();
	const { isMobile, isTablet } = useContext(DeviceServiceContext);

	const isTagDataLoading = tagData.isLoading ?? false;
	const isLoading = isTagDataLoading || specificationsQuery?.isLoading;
	const [showFullDescription, setShowFullDescription] =
		useState<boolean>(false);

	useEffect(() => {
		setShowFullDescription(false);
	}, [location]);

	const tagDescription = getLocalizedValue(
		tagData?.data?.tag?.description ?? []
	);

	let tagName = getLocalizedValue(tagData?.data?.tag?.name ?? []);
	// TODO: hardcoded for now
	if (tagName === 'Novinka') {
		tagName = 'Novinky';
	}

	const getRangeValue = useCallback(
		(filter: any) => {
			return `${t(
				{ id: 'category.filter.from' },
				{
					value: `${
						isInteger(filter.min ?? '')
							? filter.min ?? ''
							: createFormattedDecimalNumber(filter.min ?? '')
					} ${getLocalizedValue(filter.unit)}`,
				}
			)} ${t(
				{ id: 'category.filter.to' },
				{
					value: `${
						isInteger(filter.max ?? '')
							? filter.max ?? ''
							: createFormattedDecimalNumber(filter.max ?? '')
					} ${getLocalizedValue(filter.unit)}`,
				}
			)}`;
		},
		[getLocalizedValue, t]
	);

	const getPriceValue = useCallback(
		(filter: any) => {
			return `${t(
				{ id: 'category.filter.from' },
				{
					value: `${round(filter?.min, 0) ?? ''} ${
						localizationContext.activeCurrency.title ?? ''
					}`,
				}
			)} ${t(
				{ id: 'category.filter.to' },
				{
					value: `${round(filter?.max, 0) ?? ''} ${
						localizationContext.activeCurrency.title ?? ''
					}`,
				}
			)}`;
		},
		[localizationContext.activeCurrency.title, t]
	);

	// This is shared accross multiple contexts - consider refactoring to a shared file
	const getComboBoxValue = useCallback(
		(filter: Record<string, any>) => {
			return (filter.selectedValues ?? [])
				.map((value: string) => {
					const specification = (
						specificationsQuery?.data?.specifications ?? []
					).find((item) => item.id === filter.specificationDefinitionId);
					const allowedValue =
						// @ts-expect-error needs to assert this is a combobox specification
						specification?.allowedValues?.[value.toLowerCase()];
					return getLocalizedValue(allowedValue?.name ?? '');
				})
				.join(', ');
		},
		[specificationsQuery?.data?.specifications, getLocalizedValue]
	);

	const getCheckboxValue = useCallback(
		(filter: any) => {
			return get(filter, 'isChecked')
				? `${t({ id: 'category.filter.checkbox.yes' })}`
				: `${t({ id: 'category.filter.checkbox.no' })}`;
		},
		[t]
	);

	const specificationFiltersAsString: string = useMemo(() => {
		const filters: string[] = [];

		forEach(activeSpecificationFilters, (filter) => {
			const filterStart = `${upperFirst(
				getLocalizedValue(get(filter, 'specificationName', []))
			)} `;
			if (get(filter, 'selectedValues')) {
				filters.push(`${filterStart} ${getComboBoxValue(filter)}`);
			} else if (
				isNumber(get(filter, 'min')) &&
				isNumber(get(filter, 'max')) &&
				get(filter, 'unit')
			) {
				filters.push(`${filterStart} ${getRangeValue(filter)}`);
			} else if (
				isNumber(get(filter, 'min')) &&
				isNumber(get(filter, 'max')) &&
				get(filter, 'currency')
			) {
				filters.push(`${filterStart} ${getPriceValue(filter)}`);
			} else if (get(filter, 'isChecked') !== undefined) {
				filters.push(`${filterStart} ${getCheckboxValue(filter)}`);
			}
		});

		if (size(filters) > 0) {
			return ` ${join(filters, ' ')}`;
		}
		return '';
	}, [
		activeSpecificationFilters,
		getCheckboxValue,
		getComboBoxValue,
		getLocalizedValue,
		getPriceValue,
		getRangeValue,
	]);

	const shouldRenderShowMoreButton = tagDescription.split('\n').length > 1;

	const tagFiltersAsString = activeTagFilters.length
		? `${t({ id: 'tags' })} ${activeTagFilters
				.map((tagFilter) => getLocalizedValue(tagFilter.name ?? []))
				.join(', ')}`
		: '';

	return (
		<div className="container mt-0">
			<div className="row">
				<div className="col-12">
					<div className="vinisto-card vinisto-category-header pb-0">
						<h1 className="vinisto-category-header__heading">
							{isLoading ? (
								<Skeleton width="200px" />
							) : (
								`${getLocalizedValue(
									tagData.data.tag.name ?? []
								)} ${specificationFiltersAsString} ${tagFiltersAsString}`
							)}
							<ShareProductLink
								className="ms-1"
								bundleName={''}
								isTabletMobile={isMobile || isTablet}
							/>
						</h1>

						<div
							className={cx(
								styles.readMoreWrap,
								shouldRenderShowMoreButton &&
									!showFullDescription &&
									styles.preview
							)}
						>
							{isLoading ? (
								<Skeleton count={1.75} />
							) : (
								<>
									<span
										dangerouslySetInnerHTML={{
											__html: tagDescription,
										}}
									/>
									{shouldRenderShowMoreButton && (
										<>
											<button
												onClick={() =>
													setShowFullDescription(
														(setShowFullDescription) => !setShowFullDescription
													)
												}
												className={styles.readMoreLink}
											>
												{showFullDescription
													? t({
															id: 'category.header.stopReading',
													  })
													: t({
															id: 'category.header.continueReading',
													  })}
											</button>
										</>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TagHeader;
